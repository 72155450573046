.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    text-align: center;
    height: 100%;
    background-color: rgb(0 0 0 / 75%);
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;



    .popover-content {
        background-color: white;
        padding: 25px;
        border: 1px solid #e2e8f0;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width:500px;
      }
  }
  
