/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  /* font-family: 'Aeonik'; */
  font-family: 'Aeonik Light';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Light.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  /* font-family: 'Aeonik'; */
  font-family: 'Aeonik Bold';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Bold.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  /* font-family: 'Aeonik'; */
  font-family: 'Aeonik Regular';
  src: url('./assets/fonts/daylight-fonts/Aeonik-Regular.woff2') format('woff2'),
      url('./assets/fonts/daylight-fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    font-family: Aeonik Regular, sans-serif;
  }
}



