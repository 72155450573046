// @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);


@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.atomic-animation-container {
    width: 100%;
    z-index: 2 !important;
    position: relative;
    // height: calc(100vh - 146px);
    height: 100%;

}

.shape {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

}

.random-shape:after {   
    // font-family: FontAwesome;
    // content: "\f005";
    // content: url('../../assets/images/background/Ellipse-2.png');

    background-image: url('../../assets/images/background/Ellipse-2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    content:"";
    width: 125px;
    height: 125px;
}


$colors: #FFD700, #FFF8DC, #DB7093, #C2B7FE, #95A9FF;
// $shapes: '\f005', '\f0c8', '\f1db', '\f096', '\f1d8', '\f1cb';
$shapes: '../../assets/images/background/Ellipse-2.png', '../../assets/images/background/Group-16.png', '../../assets/images/background/Group-41587.png','../../assets/images/background/Group-41588.png', '../../assets/images/background/Rectangle-3.png','../../assets/images/background/Rectangle-4.png','../../assets/images/background/Rectangle-5.png','../../assets/images/background/Rectangle-39571.png','../../assets/images/background/shape134-1.png','../../assets/images/background/shape134.png','../../assets/images/background/Vector-1.png','../../assets/images/background/Vector.png';
$shape-count: 50;

@for $i from 1 through $shape-count {
    $shape-size-margin: random(2);
    $rotation: random(360);
    // $speed: 50 + random(10);
    // $speed: random_range(50, 70);
    $color-key: random( length($colors) );
    $shape-color: nth( $colors, $color-key );
    $shape-key: random( length($shapes) );
    $shape-type: nth( $shapes, $shape-key );
    $random-range: random_range(40, 100);
    // $text: random(10);
    
    @keyframes shape-#{$i} {
        0% {
            transform: translate3d(0,0,0) rotate(#{$rotation + 0}deg);
        }
        100% {
            transform: translate3d(0,0,0) rotate(#{$rotation + 360}deg);
        }
    }
  
    .shape-container--#{$i} { 
        // animation: shape-#{$i} #{$speed}s linear infinite;
        animation: shape-#{$i} #{random_range(55, 75)}s linear infinite;

        .random-shape:after {
            margin: #{$shape-size-margin}rem;
            // margin: 5px;
            color: $shape-color;
            // font-size: #{$shape-size * 1}rem;
            // content: url($shape-type) ;
            background-image: url($shape-type);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: inline-block;
            content:"";
            // width: 75px;
            // height: 75px;
            width: #{$random-range}px;
            height: #{$random-range}px;
        }
    }
}

.stop-shape  {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@media (max-width: 768px) {
    .random-shape:after {
        width: 75px;
        height: 75px;
    }

    @for $i from 1 through $shape-count {
        $shape-size-margin: random(2);
        $rotation: random(360);
        $speed: 15 + random(10);
        $color-key: random( length($colors) );
        $shape-color: nth( $colors, $color-key );
        $shape-key: random( length($shapes) );
        $shape-type: nth( $shapes, $shape-key );
        $random-range: random_range(20, 60);
        
        @keyframes shape-#{$i} {
            0% {
                transform: translate3d(0,0,0) rotate(#{$rotation + 0}deg);
            }
            100% {
                transform: translate3d(0,0,0) rotate(#{$rotation + 360}deg);
            }
        }
      
        .shape-container--#{$i} { 
            animation: shape-#{$i} #{$speed}s linear infinite;
    
            .random-shape:after {
                // margin: #{$shape-size * 25}rem;
                // font-size: #{$shape-size / 20}rem !important;
                // content: url($shape-type) ;

            margin: #{$shape-size-margin * 2}rem;
            color: $shape-color;
            // font-size: #{$shape-size * 1}rem;
            // content: url($shape-type) ;
            background-image: url($shape-type);
            background-size: 100% 100%;
            // background-size: $shape-sizee $shape-sizee;
            background-repeat: no-repeat;
            display: inline-block;
            content:"";
            // width:45px;
            // height:45px;
            width: #{$random-range}px;
            height: #{$random-range}px;
            }
        }
    }
}
